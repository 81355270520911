import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DesignToolService {

  constructor(private httpResp: HttpClient) { }

  //public baseURL = 'http://localhost:8000/api/'; // *local server
  public baseURL = 'https://api-designtool.optech.pk/api/'; // *Live server

  //public imgURL = 'http://localhost:8000'; // *local server
  public imgURL = 'https://api-designtool.optech.pk'; // *Live server

  //#region auth routes
  userLogIn(email, Password) {
    let data = [];

    data.push({
      email: email,
      password: Password
    })
    return this.httpResp.post(this.baseURL + 'auth/login', data[0]);
  }

  userLogOut() {
    let auth_token = localStorage.getItem("token");
    let reqHeaders = new HttpHeaders({ 'Authorization': `Bearer ${auth_token}`, 'accept': 'application/json' });
    return this.httpResp.post(this.baseURL + 'auth/logout', { headers: reqHeaders });
  }
  //#endregion

  //#region product routes
  getCategories(): Observable<any> {
    let auth_token = localStorage.getItem("token");
    let reqHeaders = new HttpHeaders({ 'Authorization': `Bearer ${auth_token}`, 'accept': 'application/json' });
    return this.httpResp.get(this.baseURL + 'admin/get-product-categories', { headers: reqHeaders });
  }

  getSubCategoriesByCatID(id): Observable<any> {
    let auth_token = localStorage.getItem("token");
    let reqHeaders = new HttpHeaders({ 'Authorization': `Bearer ${auth_token}`, 'accept': 'application/json' });
    return this.httpResp.get(this.baseURL + 'admin/get-product-subcat-by-catid/' + id, { headers: reqHeaders });
  }

  saveProduct(data): Observable<any> {
    let auth_token = localStorage.getItem("token");
    let reqHeaders = new HttpHeaders({ 'Authorization': `Bearer ${auth_token}`, 'accept': 'application/json' });
    return this.httpResp.post<any>(this.baseURL + 'admin/create-product', data, { headers: reqHeaders });
  }

  addCategory(data): Observable<any> {
    let auth_token = localStorage.getItem("token");
    let reqHeaders = new HttpHeaders({ 'Authorization': `Bearer ${auth_token}`, 'accept': 'application/json' });
    return this.httpResp.post<any>(this.baseURL + 'admin/create-product-category', data, { headers: reqHeaders });
  }

  addSubCategory(data): Observable<any> {
    let auth_token = localStorage.getItem("token");
    let reqHeaders = new HttpHeaders({ 'Authorization': `Bearer ${auth_token}`, 'accept': 'application/json' });
    return this.httpResp.post<any>(this.baseURL + 'admin/create-product-category', data, { headers: reqHeaders });
  }
  //#endregion

  //#region brand routes
  getBrands(): Observable<any> {
    let auth_token = localStorage.getItem("token");
    let reqHeaders = new HttpHeaders({ 'Authorization': `Bearer ${auth_token}`, 'accept': 'application/json' });
    return this.httpResp.get(this.baseURL + 'admin/get-brands', { headers: reqHeaders });
  }

  addBrand(data): Observable<any> {
    let auth_token = localStorage.getItem("token");
    let reqHeaders = new HttpHeaders({ 'Authorization': `Bearer ${auth_token}`, 'accept': 'application/json' });
    return this.httpResp.post<any>(this.baseURL + 'admin/create-brand', data, { headers: reqHeaders });
  }
  //#endregion

  //#region fabric routes
  getFabrics(): Observable<any> {
    let auth_token = localStorage.getItem("token");
    let reqHeaders = new HttpHeaders({ 'Authorization': `Bearer ${auth_token}`, 'accept': 'application/json' });
    return this.httpResp.get(this.baseURL + 'admin/get-fabrics', { headers: reqHeaders });
  }

  getFabricCategories(): Observable<any> {
    let auth_token = localStorage.getItem("token");
    let reqHeaders = new HttpHeaders({ 'Authorization': `Bearer ${auth_token}`, 'accept': 'application/json' });
    return this.httpResp.get(this.baseURL + 'admin/get-fabric-categories', { headers: reqHeaders });
  }

  addFabric(data): Observable<any> {
    let auth_token = localStorage.getItem("token");
    let reqHeaders = new HttpHeaders({ 'Authorization': `Bearer ${auth_token}`, 'accept': 'application/json' });
    return this.httpResp.post<any>(this.baseURL + 'admin/create-fabric', data, { headers: reqHeaders });
  }

  addFabricCategory(data): Observable<any> {
    let auth_token = localStorage.getItem("token");
    let reqHeaders = new HttpHeaders({ 'Authorization': `Bearer ${auth_token}`, 'accept': 'application/json' });
    return this.httpResp.post<any>(this.baseURL + 'admin/create-fabric-category', data, { headers: reqHeaders });
  }
  //#endregion

  //#region artwork routes
  getArtWorkCategories(): Observable<any> {
    let auth_token = localStorage.getItem("token");
    let reqHeaders = new HttpHeaders({ 'Authorization': `Bearer ${auth_token}`, 'accept': 'application/json' });
    return this.httpResp.get(this.baseURL + 'admin/get-artwork-category', { headers: reqHeaders });
  }

  addArtWorkCategory(data): Observable<any> {
    let auth_token = localStorage.getItem("token");
    let reqHeaders = new HttpHeaders({ 'Authorization': `Bearer ${auth_token}`, 'accept': 'application/json' });
    return this.httpResp.post<any>(this.baseURL + 'admin/create-artwork-category', data, { headers: reqHeaders });
  }
  //#endregion

  //#region fittype routes
  getFittypes(): Observable<any> {
    let auth_token = localStorage.getItem("token");
    let reqHeaders = new HttpHeaders({ 'Authorization': `Bearer ${auth_token}`, 'accept': 'application/json' });
    return this.httpResp.get(this.baseURL + 'admin/get-fittypes', { headers: reqHeaders });
  }

  addSize(data): Observable<any> {
    let auth_token = localStorage.getItem("token");
    let reqHeaders = new HttpHeaders({ 'Authorization': `Bearer ${auth_token}`, 'accept': 'application/json' });
    return this.httpResp.post<any>(this.baseURL + 'admin/create-fittype', data, { headers: reqHeaders });
  }
  //#endregion  

  //#region color routes
  getColors(): Observable<any> {
    let auth_token = localStorage.getItem("token");
    let reqHeaders = new HttpHeaders({ 'Authorization': `Bearer ${auth_token}`, 'accept': 'application/json' });
    return this.httpResp.get(this.baseURL + 'admin/get-colors', { headers: reqHeaders });
  }

  addColor(data): Observable<any> {
    let auth_token = localStorage.getItem("token");
    let reqHeaders = new HttpHeaders({ 'Authorization': `Bearer ${auth_token}`, 'accept': 'application/json' });
    return this.httpResp.post<any>(this.baseURL + 'admin/create-color', data, { headers: reqHeaders });
  }
  //#endregion  

  //#region default image
  saveDefaultImageCord(data): Observable<any> {
    let auth_token = localStorage.getItem("token");
    let reqHeaders = new HttpHeaders({ 'Authorization': `Bearer ${auth_token}`, 'accept': 'application/json' });
    return this.httpResp.post<any>(this.baseURL + 'admin/set-print-area', data, { headers: reqHeaders });
  }
  //#endregion  

  //#region font routes
  getGoogleFonts(): Observable<any> {
    return this.httpResp.get('https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyCzzXcLWoS35nE_Eattnb4OpDE9-RpeTV0');
  }

  addFont(data): Observable<any> {
    let auth_token = localStorage.getItem("token");
    let reqHeaders = new HttpHeaders({ 'Authorization': `Bearer ${auth_token}`, 'accept': 'application/json' });
    return this.httpResp.post<any>(this.baseURL + 'admin/add-font', data, { headers: reqHeaders });
  }
  //#endregion  


  uploadImage(data): Observable<any> {
    console.log(data)
    let auth_token = localStorage.getItem("token");
    let reqHeaders = new HttpHeaders({ 'Authorization': `Bearer ${auth_token}`, 'accept': 'application/json' });
    return this.httpResp.post<any>(this.baseURL + 'admin/upload-product-image', data, { headers: reqHeaders });
  }
}
