<mat-toolbar class="p-0 mat-elevation-z1">

    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

            <!-- <fuse-search-bar (input)="search($event)"></fuse-search-bar> -->

        </div>

        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">


            <button mat-button [matMenuTriggerFor]="userMenu"
                    class="user-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <!-- <span class="username mr-12" fxHide fxShow.gt-sm>Anwar Ali</span> -->
                    <mat-icon>account_circle</mat-icon>
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item>
                    <mat-icon>account_circle</mat-icon>
                    <span>My Profile</span>
                </button>

                <button mat-menu-item class="" (click)="LogOut()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Logout</span>
                </button>

            </mat-menu>

        </div>

    </div>

</mat-toolbar>
