import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { DesignToolService } from 'app/design-tool.service';

@Injectable()
export class OrderListService implements Resolve<any> {


  products: any[];
  onProductsChanged: BehaviorSubject<any>;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(
    private _httpClient: HttpClient,
    private DesignToolService: DesignToolService
  ) {
    // Set the defaults
    this.onProductsChanged = new BehaviorSubject({});
  }
  baseURL = this.DesignToolService.baseURL;
  imgUrl = this.DesignToolService.imgURL;

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {

      Promise.all([
        this.getOrders()
      ]).then(
        () => {
          resolve();
        },
        reject
      );
    });
  }

  /**
   * Get Orders
   *
   * @returns {Promise<any>}
   */
  getOrders(): Promise<any> {
    return new Promise((resolve, reject) => {
      let auth_token = localStorage.getItem("token");
      let reqHeaders = new HttpHeaders({ 'Authorization': `Bearer ${auth_token}`, 'accept': 'application/json' });
      this._httpClient.get(this.baseURL + 'admin/getOrder', { headers: reqHeaders })
        .subscribe((response: any) => {
          this.products = response;
          this.onProductsChanged.next(this.products);
          resolve(response);
        }, reject);
    });
  }
}

