import { NgModule, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AgmCoreModule } from '@agm/core';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FormsModule } from "@angular/forms";
import { ImageCropperModule } from 'ngx-image-cropper';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxSpinnerModule } from 'ngx-spinner';

import { AddProductComponent } from './add-product/add-product.component';
import { AngularFileUploaderModule } from 'angular-file-uploader';
import { AddSizeComponent } from './add-size/add-size.component';
import { AddColorComponent } from './add-color/add-color.component';
import { AddCategoryComponent } from './add-category/add-category.component';
import { AddSubcategoryComponent } from './add-subcategory/add-subcategory.component';
import { ViewCategoryComponent } from './view-category/view-category.component';
import { ProductCategoryListService } from './view-category/product-category-list.service';
import { ViewColorComponent } from './view-color/view-color.component';
import { ColorListService } from './view-color/color-list.service';
import { ViewProductComponent } from './view-product/view-product.component';
import { ProductListService } from './view-product/product-list.service';
import { ViewSizeComponent } from './view-size/view-size.component';
import { SizeListService } from './view-size/size-list.service';
import { ViewSubcategoryComponent } from './view-subcategory/view-subcategory.component';
import { ProductSubcategoryListService } from './view-subcategory/product-subcategory-list.service';
import { FrontPixelToInchDialog } from './add-product/add-product.component'
import { BackPixelToInchDialog } from './add-product/add-product.component'


const routes: Routes = [

  {
    path: 'add-product',
    component: AddProductComponent
  },
  {
    path: 'add-size',
    component: AddSizeComponent
  },
  {
    path: 'add-color',
    component: AddColorComponent
  },
  {
    path: 'add-category',
    component: AddCategoryComponent
  },
  {
    path: 'add-subcategory',
    component: AddSubcategoryComponent
  },
  {
    path: 'product-category',
    component: ViewCategoryComponent,
    resolve: {
      data: ProductCategoryListService
    }
  },
  {
    path: 'product-subcategory',
    component: ViewSubcategoryComponent,
    resolve: {
      data: ProductSubcategoryListService
    }
  },
  {
    path: 'colors',
    component: ViewColorComponent,
    resolve: {
      data: ColorListService
    }
  },
  {
    path: 'products',
    component: ViewProductComponent,
    resolve: {
      data: ProductListService
    }
  },
  {
    path: 'sizes',
    component: ViewSizeComponent,
    resolve: {
      data: SizeListService
    }
  }

];

@NgModule({
  declarations: [AddProductComponent, AddSizeComponent, AddColorComponent, AddCategoryComponent, AddSubcategoryComponent, ViewCategoryComponent, ViewColorComponent, ViewProductComponent, ViewSizeComponent, ViewSubcategoryComponent, FrontPixelToInchDialog, BackPixelToInchDialog],
  imports: [
    FormsModule,
    CommonModule,
    RouterModule.forChild(routes),
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatStepperModule,
    MatPaginatorModule,
    MatTableModule,
    MatChipsModule,
    MatRippleModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatSortModule,
    MatTabsModule,
    MatCheckboxModule,
    NgxChartsModule,
    AgmCoreModule,
    FuseWidgetModule,

    //npm modules
    NgxSpinnerModule,

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
    AngularFileUploaderModule,
    ImageCropperModule,
    ColorPickerModule
  ],
  providers: [
    ProductCategoryListService,
    ProductSubcategoryListService,
    ColorListService,
    ProductListService,
    SizeListService
  ]
})
export class ProductManagementModule { }
