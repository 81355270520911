import { Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Subject } from 'rxjs';
import { DesignToolService } from 'app/design-tool.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import Cropper from "cropperjs";
import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss'],
  providers: [ DatePipe ],
})
export class AddProductComponent implements OnInit, OnDestroy {

  showCanvasImage = {
    front: true,
    back: false,
  }

  public formData = new FormData();

  public uploadMaxSize = 1000000; // 1 mb
  public mainProductImage: File = null;
  public ProductImage2: File = null;
  public SliderImages: Array<File> = [];
  public ProductImage3: File = null;
  public ProductImage4: File = null;
  public ProductImage5: File = null;
  public image1Path: any;
  public image2Path: any;
  public picker: string;
  public sliderImagePath: any=[];
  public frontImageLeft: any;
  public frontImageTop: any;
  public frontImageWidth: any;
  public frontImageHeight: any;
  public backImageLeft: any;
  public backImageTop: any;
  public backImageWidth: any;
  public backImageHeight: any;
  public frontWidthPxPerInch: string = '';
  public frontHeightPxPerInch: string = '';
  public backWidthPxPerInch: string = '';
  public backHeightPxPerInch: string = '';
  public images: Array<Object> = [];
  public imagesCount: number=0

  public qty: number = 0;

  public brands = [];
  public colors = [];
  public fabrics = [];
  public fittypes = [];
  public categories = [];
  public subCategories = [];

  public selectedFittypeValue = [];
  public selectedColorValue = [];

  public disabledSubCategorySelect: Boolean = true;

  public disabledFront: boolean = true;
  public disabledBack: boolean = true;

  @ViewChild("image1", { static: false })
  public image1Element: ElementRef;
  @ViewChild("image2", { static: false })
  public image2Element: ElementRef;
  private cropper: Cropper;

  // Horizontal Stepper
  horizontalStepperStep1: FormGroup;
  horizontalStepperStep2: FormGroup;
  horizontalStepperStep3: FormGroup;
  horizontalStepperStep4: FormGroup;
  horizontalStepperStep5: FormGroup;
  horizontalStepperStep6: FormGroup;
  horizontalStepperStep7: FormGroup;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {FormBuilder} _formBuilder
   */
  constructor(
    public dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private DesignToolService: DesignToolService,
    private router: Router,
    private datePipe: DatePipe,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  
  ngOnInit(): void {
    this.getBrands();
    this.getColors();
    this.getFabrics();
    this.getFittypes();
    this.getCategories();
    

    // Horizontal Stepper form steps
    this.horizontalStepperStep1 = this._formBuilder.group({
      name: ['', Validators.required],
      category: ['', Validators.required],
      type: ['', Validators.required],
      subcategory: ['', Validators.required],
      brand: ['', Validators.required],
      fabric: ['', Validators.required],
      description: ['',Validators.required]
    });
    
    this.horizontalStepperStep2 = this._formBuilder.group({
      price: ['', Validators.required],
      discount: ['0'],
      qty: ['', Validators.required]
    });

    this.horizontalStepperStep3 = this._formBuilder.group({
      fittype: ['', Validators.required],
      color: ['', Validators.required],
    });

    this.horizontalStepperStep4 = this._formBuilder.group({
      image1: ['', Validators.required],
      image2: ['', Validators.required]
    });
    this.horizontalStepperStep5 = this._formBuilder.group({
      slider: ['', Validators.required,],
    
    });
    
    this.horizontalStepperStep6 = this._formBuilder.group({
      features: ['', Validators.required],
      miniqty: ['', Validators.required],
      productinformation: ['', Validators.required]
    });
   
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }


  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  getImage(event, imagePos) {
    if (event.target.files[0].size > this.uploadMaxSize) {
      event.target.value = null;
      Swal.fire('Warning', 'Max upload size should be less than equal to (250kb)', 'warning')
    } else {
      if (imagePos == '1') {
        this.mainProductImage = <File>event.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(this.mainProductImage);
        reader.onload = (_event) => {
          this.image1Path = reader.result;
        }
      } else {
        this.ProductImage2 = <File>event.target.files[0];

        let reader = new FileReader();
        reader.readAsDataURL(this.ProductImage2);
        reader.onload = (_event) => {
          this.image2Path = reader.result;
        }
      }
    }
  }
  getImages(event) {
    for (let index = 0; index <event.target.files.length; index++) {
      if (event.target.files[index].size > this.uploadMaxSize) {
        event.target.value = null;
        Swal.fire('Warning', 'Max upload size should be less than equal to (250kb)', 'warning')
      } else {
       let images= this.uploadImage(event.target.files[index])
          this.SliderImages.push(<File>event.target.files[index]);
          let reader = new FileReader();
            reader.readAsDataURL(event.target.files[index]);
            reader.onload = (_event) => {
              this.sliderImagePath.push(reader.result)
            }      
      }
      
    }
    
  }

  getCategories() {
    try {
      this.DesignToolService.getCategories().subscribe(
        (result: any) => {
          if (result.responseCode == 200 && result.status == true) {
            this.categories = result.data;
          } else {
            Swal.fire("", result.message, "error");
          }
        },
        (error: HttpErrorResponse) => {
          Swal.fire("", "Something went wrong", "error");
        }
      );
    } catch (error) {
      Swal.fire("", "Something went wrong", "error");
    }
  }

  getSubCategories(value) {
    try {
      this.DesignToolService.getSubCategoriesByCatID(value).subscribe(
        (result: any) => {
          if (result.responseCode == 200 && result.status == true) {
            this.subCategories = result.data;
            if (this.subCategories.length > 0) {
              this.disabledSubCategorySelect = false;
            }
            else {
              Swal.fire('', 'No Sub Category found', 'warning')
              this.disabledSubCategorySelect = true;
            }
          } else {
            Swal.fire("", result.message, "error");
          }
        },
        (error: HttpErrorResponse) => {
          Swal.fire("", "Something went wrong", "error");
        }
      );
    } catch (error) {
      Swal.fire("", "Something went wrong", "error");
    }
  }

  getSubCategoryQty(id){
    let subcategories = this.subCategories;

    subcategories.forEach(e => {
      if(e.id == id){
        this.qty = e.qty;
      }
    });
  }

  getBrands() {
    try {
      this.DesignToolService.getBrands().subscribe(
        (result: any) => {
          if (result.responseCode == 200 && result.status == true) {
            this.brands = result.data;
          } else {
            Swal.fire("", result.message, "error");
          }
        },
        (error: HttpErrorResponse) => {
          Swal.fire("", "Something went wrong", "error");
        }
      );
    } catch (error) {
      Swal.fire("", "Something went wrong", "error");
    }
  }

  getFabrics() {
    try {
      this.DesignToolService.getFabrics().subscribe(
        (result: any) => {
          if (result.responseCode == 200 && result.status == true) {
            this.fabrics = result.data;
          } else {
            Swal.fire("", result.message, "error");
          }
        },
        (error: HttpErrorResponse) => {
          Swal.fire("", "Something went wrong", "error");
        }
      );
    } catch (error) {
      Swal.fire("", "Something went wrong", "error");
    }
  }

  getFittypes() {
    try {
      this.DesignToolService.getFittypes().subscribe(
        (result: any) => {
          if (result.responseCode == 200 && result.status == true) {
            this.fittypes = result.data;
          } else {
            Swal.fire("", result.message, "error");
          }
        },
        (error: HttpErrorResponse) => {
          Swal.fire("", "Something went wrong", "error");
        }
      );
    } catch (error) {
      Swal.fire("", "Something went wrong", "error");
    }
  }

  getColors() {
    try {
      this.DesignToolService.getColors().subscribe(
        (result: any) => {
          if (result.responseCode == 200 && result.status == true) {
            this.colors = result.data;
          } else {
            Swal.fire("", result.message, "error");
          }
        },
        (error: HttpErrorResponse) => {
          Swal.fire("", "Something went wrong", "error");
        }
      );
    } catch (error) {
      Swal.fire("", "Something went wrong", "error");
    }
  }

  getFrontImageCord() {
    this.cropper = new Cropper(this.image1Element.nativeElement, {
      zoomable: false,
      scalable: true,
      aspectRatio: 0,
      crop: () => {
        const canvas = this.cropper.getCropBoxData();
        this.frontImageLeft = canvas.left;
        this.frontImageTop = canvas.top;
        this.frontImageWidth = canvas.width;
        this.frontImageHeight = canvas.height;
      },
      cropend: () => {
        this.disabledFront = false;
      }
    });
  }

  getBackImageCord() {
    this.cropper = new Cropper(this.image2Element.nativeElement, {
      zoomable: false,
      scalable: true,
      aspectRatio: 0,
      crop: () => {
        const canvas = this.cropper.getCropBoxData();
        this.backImageLeft = canvas.left;
        this.backImageTop = canvas.top;
        this.backImageWidth = canvas.width;
        this.backImageHeight = canvas.height;
      },
      cropend: () => {
        this.disabledBack = false;
      }
    });
  }

  openFrontDialog() {
    const dialogRef = this.dialog.open(FrontPixelToInchDialog, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.frontWidthPxPerInch = result.widthPxPerInch;
      this.frontHeightPxPerInch = result.heightPxPerInch;
    });
  }

  openBackDialog() {
    const dialogRef = this.dialog.open(BackPixelToInchDialog, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.backWidthPxPerInch = result.widthPxPerInch;
      this.backHeightPxPerInch = result.heightPxPerInch;
    });
  }

  saveProduct() {
    if (this.horizontalStepperStep1.invalid || this.horizontalStepperStep2.invalid || this.horizontalStepperStep3.invalid || this.horizontalStepperStep4.invalid) {
      return;
    } else if(this.frontWidthPxPerInch === '' || this.frontHeightPxPerInch === ''){
      Swal.fire('Error', 'Front Image Pixel To Inch Ration Required!', 'error');
    } else if(this.backWidthPxPerInch === '' || this.backHeightPxPerInch === '') {
      Swal.fire('Error', 'Back Image Pixel To Inch Ration Required!', 'error');
    } else {
      this.spinner.show();
      let fittypeArray = [];
      let colorArray = [];

      let name = this.horizontalStepperStep1.value['name'];
      let type = this.horizontalStepperStep1.value['type'];
      let category = this.horizontalStepperStep1.value['category'];
      let subcategory = this.horizontalStepperStep1.value['subcategory'];
      let brand = this.horizontalStepperStep1.value['brand'];
      let fabric = this.horizontalStepperStep1.value['fabric'];
      let description = this.horizontalStepperStep1.value['description'];
      let price = this.horizontalStepperStep2.value['price'];
      let discount = this.horizontalStepperStep2.value['discount'];
      let qty = this.horizontalStepperStep2.value['qty'];
      let features = this.horizontalStepperStep6.value['features'];
      let miniqty = this.horizontalStepperStep6.value['miniqty'];
      let productinformation = this.horizontalStepperStep6.value['productinformation'];

      let fittype = this.selectedFittypeValue;
      let color = this.selectedColorValue;

      fittype.forEach(e => {
        fittypeArray.push({ "fittype_id": e })
      });

      color.forEach(e => {
        colorArray.push({ "color_id": e })
      });

      this.formData.append('name', name);
      this.formData.append('category_id', category);
      this.formData.append('type', type);
      this.formData.append('subcategory_id', subcategory);
      this.formData.append('brand_id', brand);
      this.formData.append('fabric_id', fabric);
      this.formData.append('qty', qty);
      this.formData.append('price', price);
      this.formData.append('discount', discount);
      this.formData.append('description', description);
      this.formData.append('fittype', JSON.stringify(fittypeArray));
      this.formData.append('color', JSON.stringify(colorArray));
      this.formData.append('features',features);
      this.formData.append('minimum_qty',miniqty);
      this.formData.append('product_information',productinformation);
      this.formData.append('image1', this.mainProductImage, this.mainProductImage.name);
      this.formData.append('image2', this.ProductImage2, this.ProductImage2.name);
      this.formData.append('images', JSON.stringify(this.images));
      this.formData.append('front_img_left', parseFloat(this.frontImageLeft).toFixed(2));
      this.formData.append('front_img_top', parseFloat(this.frontImageTop).toFixed(2));
      this.formData.append('front_img_width', parseFloat(this.frontImageWidth).toFixed(2));
      this.formData.append('front_img_height', parseFloat(this.frontImageHeight).toFixed(2));
      this.formData.append('back_img_left', parseFloat(this.backImageLeft).toFixed(2));
      this.formData.append('back_img_top', parseFloat(this.backImageTop).toFixed(2));
      this.formData.append('back_img_width', parseFloat(this.backImageWidth).toFixed(2));
      this.formData.append('back_img_height', parseFloat(this.backImageHeight).toFixed(2));
      this.formData.append('front_width_px_per_inch', this.frontWidthPxPerInch);
      this.formData.append('front_height_px_per_inch', this.frontHeightPxPerInch);
      this.formData.append('back_width_px_per_inch', this.backWidthPxPerInch);
      this.formData.append('back_height_px_per_inch', this.backHeightPxPerInch);

      this.DesignToolService.saveProduct(this.formData).subscribe((result: any) => {
        this.spinner.hide();
        if (result.responseCode == 200 && result.status == true) {
          Swal.fire('Success', 'Product Created Successfully', 'success').then(function () {
            window.location.reload();
          });
        } else if (result.responseCode == 400 && result.status == false) {
          Swal.fire('Error', result.message, 'error');
        } else {
          Swal.fire('Error', 'Something went wrong', 'error');
        }
      }, (error: HttpErrorResponse) => {
        this.spinner.hide();
        if (error.status == 401) {
          Swal.fire('Error', error.error.message, 'error');
        } else {
          Swal.fire('Error', 'Something went wrong', 'error');
        }
      });
    }
  }
  uploadImage(image) {
    this.formData.append('image', image);
        this.DesignToolService.uploadImage(this.formData).subscribe((result: any) => {
            this.spinner.hide();
            if (result.responseCode == 200 && result.status == true) {
              this.images.push({image:result.data})
                // Swal.fire('Success', 'Image Created Successfully', 'success').then(function () {
                //     // window.location.reload();
                // });
            } else if (result.responseCode == 400 && result.status == false) {
                Swal.fire('Error', result.message, 'error');
            } else {
                Swal.fire('Error', 'Something went wrong', 'error');
            }
        }, (error: HttpErrorResponse) => {
            this.spinner.hide();
            if (error.status == 401) {
                Swal.fire('Error', error.error.message, 'error');
            } else {
                Swal.fire('Error', 'Something went wrong', 'error');
            }
        });
    }
  }



@Component({
  selector: 'front-pixel-to-inch-dialog',
  templateUrl: 'front-pixel-to-inch-dialog.html',
  styleUrls: ['./add-product.component.scss']
})
export class FrontPixelToInchDialog {

  public form;


  @ViewChild("image", { static: false })
  public imageElement: ElementRef;

  constructor(
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<FrontPixelToInchDialog>,
  ) { }

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      widthInch: new FormControl({ value: '1', disabled: true }),
      heightInch: new FormControl({ value: '1', disabled: true }),
      widthPxPerInch: ['', Validators.required],
      heightPxPerInch: ['', Validators.required],
    });
  }

  done() {
    if (this.form.invalid) {
      return false;
    } else {
      this.dialogRef.close({ event: 'close', widthPxPerInch: this.form.value['widthPxPerInch'], heightPxPerInch: this.form.value['heightPxPerInch'] });
    }
  }

}

@Component({
  selector: 'back-pixel-to-inch-dialog',
  templateUrl: 'back-pixel-to-inch-dialog.html',
  styleUrls: ['./add-product.component.scss']
})
export class BackPixelToInchDialog {

  public form;

  @ViewChild("image", { static: false })
  public imageElement: ElementRef;

  constructor(
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<BackPixelToInchDialog>,
  ) { }

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      widthInch: new FormControl({ value: '1', disabled: true }),
      heightInch: new FormControl({ value: '1', disabled: true }),
      widthPxPerInch: ['', Validators.required],
      heightPxPerInch: ['', Validators.required],
    });
  }

  done() {
    if (this.form.invalid) {
      return false;
    } else {
      this.dialogRef.close({ event: 'close', widthPxPerInch: this.form.value['widthPxPerInch'], heightPxPerInch: this.form.value['heightPxPerInch'] });
    }
  }

}
