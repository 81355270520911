<div id="forms" class="page-layout simple fullwidth" fxLayout="column">

    <!-- HEADER -->
    <div class="header accent p-24 h-160" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column" fxLayoutAlign="center start">
            <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="secondary-text s-18">home</mat-icon>
                <mat-icon class="secondary-text s-16">chevron_right</mat-icon>
                <span class="secondary-text">Products Management</span>
            </div>
            <div class="h1 mt-16">Add Product</div>
        </div>
    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content p-24">
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-spin-fade" [fullScreen]="true">
        </ngx-spinner>

        <div class="horizontal-stepper-wrapper">

            <!-- HORIZONTAL STEPPER EXAMPLE -->
            <mat-horizontal-stepper class="mat-elevation-z4" [linear]="true">

                <mat-step [stepControl]="horizontalStepperStep1">

                    <form fxLayout="column" [formGroup]="horizontalStepperStep1">

                        <ng-template matStepLabel>Info</ng-template>

                        <div fxFlex="1 0 auto" fxLayout="row">

                            <mat-form-field appearance="outline" fxFlex="50" class="mr-4">
                                <mat-label>Product Name</mat-label>
                                <input matInput formControlName="name" required>
                                <mat-error>Product Name is required!</mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="50" class="ml-4">
                                <mat-label>Category</mat-label>
                                <mat-select formControlName="category"
                                    (selectionChange)="getSubCategories($event.value)">
                                    <mat-option *ngFor="let item of categories" [value]="item.id" required>
                                        {{item.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-error>Category is required!</mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="50" class="ml-4">
                                <mat-label>Type</mat-label>
                                <mat-select formControlName="type"
                                   >
                                    <mat-option value="Customizable" required>
                                        Customizable
                                    </mat-option>
                                    <mat-option value="NotCustomizable" required>
                                        Non-Customizable
                                    </mat-option>
                                </mat-select>
                                <mat-error>Type is required!</mat-error>
                            </mat-form-field>

                        </div>

                        <div fxFlex="1 0 auto" fxLayout="row">

                            <mat-form-field appearance="outline" fxFlex="33" class="mr-4">
                                <mat-label>SubCategory</mat-label>
                                <mat-select formControlName="subcategory" [disabled]="disabledSubCategorySelect"
                                    (selectionChange)="getSubCategoryQty($event.value)" required>
                                    <mat-option *ngFor="let item of subCategories" [value]="item.id">
                                        {{item.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-error>SubCategory is required!</mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="33" class="ml-4 mr-4">
                                <mat-label>Brand</mat-label>
                                <mat-select formControlName="brand" required>
                                    <mat-option *ngFor="let item of brands" [value]="item.id">
                                        {{item.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-error>Brand is required!</mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="33" class="ml-4">
                                <mat-label>Fabric</mat-label>
                                <mat-select formControlName="fabric" required>
                                    <mat-option *ngFor="let item of fabrics" [value]="item.id">
                                        {{item.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-error>Fabric is required!</mat-error>
                            </mat-form-field>

                        </div>

                        <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">

                            <mat-form-field appearance="outline" fxFlex="100">
                                <mat-label>Description</mat-label>
                                <textarea matInput formControlName="description" required>
                                </textarea>
                                <mat-error>Description is required!</mat-error>
                            </mat-form-field>

                        </div>

                        <div fxLayout="row" fxLayoutAlign="center center">
                            <button mat-raised-button matStepperNext type="button" color="accent">
                                Next
                            </button>
                        </div>

                    </form>

                </mat-step>

                <mat-step [stepControl]="horizontalStepperStep2">

                    <form fxLayout="column" [formGroup]="horizontalStepperStep2">

                        <ng-template matStepLabel>Price & Qty</ng-template>

                        <div fxFlex="1 0 auto" fxLayout="row">

                            <mat-form-field appearance="outline" fxFlex="33" class="mr-4">
                                <mat-label>Price</mat-label>
                                <input type="number" matInput formControlName="price" required>
                                <mat-error>Price is required!</mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="33" class="mr-4 ml-4">
                                <mat-label>Discount</mat-label>
                                <input type="number" matInput formControlName="discount">
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="33" class="ml-4">
                                <mat-label>Quantity</mat-label>
                                <input type="number" matInput formControlName="qty" [(ngModel)]="qty" required>
                                <mat-error>Quantity is required!</mat-error>
                            </mat-form-field>

                        </div>

                        <div fxLayout="row" fxLayoutAlign="center center">
                            <button class="mr-8" mat-raised-button matStepperPrevious type="button" color="accent">
                                Previous
                            </button>
                            <button mat-raised-button matStepperNext type="button" color="accent">
                                Next
                            </button>
                        </div>

                    </form>

                </mat-step>

                <mat-step [stepControl]="horizontalStepperStep3">

                    <form fxLayout="column" [formGroup]="horizontalStepperStep3">

                        <ng-template matStepLabel>Size & Color</ng-template>

                        <div fxFlex="1 0 auto" fxLayout="column">

                            <mat-form-field appearance="outline" fxFlex="100">
                                <mat-label>Fittype</mat-label>
                                <mat-select formControlName="fittype" [(ngModel)]="selectedFittypeValue" multiple
                                    required>
                                    <mat-option *ngFor="let item of fittypes" [value]="item.id">
                                        {{item.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-error>Fittype is required!</mat-error>
                            </mat-form-field>

                        </div>

                        <div fxFlex="1 0 auto" fxLayout="column">

                            <mat-form-field appearance="outline" fxFlex="100">
                                <mat-label>Color</mat-label>
                                <mat-select formControlName="color" [(ngModel)]="selectedColorValue" multiple>
                                    <mat-option *ngFor="let item of colors" [value]="item.id"
                                        style="text-transform: capitalize;">
                                        {{item.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-error>Color is required!</mat-error>
                            </mat-form-field>

                        </div>

                        <div fxLayout="row" fxLayoutAlign="center center">
                            <button class="mr-8" mat-raised-button matStepperPrevious type="button" color="accent">
                                Previous
                            </button>
                            <button mat-raised-button matStepperNext type="button" color="accent">
                                Next
                            </button>
                        </div>

                    </form>

                </mat-step>

                <mat-step [stepControl]="horizontalStepperStep4">

                    <form fxLayout="column" [formGroup]="horizontalStepperStep4">

                        <ng-template matStepLabel>Images</ng-template>

                        <div class="mb-24 mt-24" fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="center center"
                            fxLayoutGap="10px">

                            <div fxFlex="50%" class="text-center">
                                <!-- <h2 class="text-center">Front Image</h2> -->
                                <div class="main-product-image-wrapper">
                                    <img class="image" *ngIf="image1Path != null" [src]="image1Path">
                                    <span>1600 x 1600</span>
                                </div>
                                <button mat-raised-button type="button" color="accent" class="button-wrapper">
                                    <input type="file" formControlName="image1" (change)="getImage($event,1)"
                                        accept="image/png, image/jpeg, image/jpg">
                                    <mat-icon>cloud_upload</mat-icon>
                                    Front Image
                                </button>
                            </div>

                            <div fxFlex="50%" class="text-center">
                                <!-- <h2 class="text-center">Back Image</h2> -->
                                <div class="main-product-image-wrapper">
                                    <img class="image" *ngIf="image2Path != null" [src]="image2Path">
                                    <span>1600 x 1600</span>
                                </div>
                                <button mat-raised-button type="button" color="accent" class="button-wrapper">
                                    <input type="file" formControlName="image2" (change)="getImage($event,2)"
                                        accept="image/png, image/jpeg, image/jpg">
                                    <mat-icon>cloud_upload</mat-icon>
                                    Back Image
                                </button>
                            </div>

                        </div>

                        <div fxLayout="row" fxLayoutAlign="center center">
                            <button class="mr-8" mat-raised-button matStepperPrevious type="button" color="accent">
                                Previous
                            </button>
                            <button mat-raised-button matStepperNext type="button" color="accent">
                                Next
                            </button>
                        </div>

                    </form>

                </mat-step>
                <mat-step [stepControl]="horizontalStepperStep5">

                    <form fxLayout="column" [formGroup]="horizontalStepperStep5">

                        <ng-template matStepLabel>Product Images Slider</ng-template>

                        <div class="mb-24 mt-24"  fxLayout="row wrap" fxLayoutAlign="center center"
                            fxLayoutGap="20px grid">
                            <div *ngFor="let image of sliderImagePath">
                            <div fxFlex="1 0 200px" class="text-center">
                                <!-- <h2 class="text-center">Front Image</h2> -->
                               
                                <div class="main-product-image-wrapper">
                                    <img class="image"  *ngIf="sliderImagePath.length !=0" [src]="image">
                                    <span>1600 x 1600</span>
                                </div>
                            </div>
                              
                            </div>
                           


                        </div>
                        <div style="margin-bottom:10px ;" fxLayout="row" fxLayoutAlign="center center">
                            <button mat-raised-button type="button" color="accent" class="button-wrapper">
                                <input type="file" multiple formControlName="slider" (change)="getImages($event)"
                                    accept="image/png, image/jpeg, image/jpg">
                                <mat-icon>cloud_upload</mat-icon>
                                Upload Images
                            </button>

                           </div>
                        <div fxLayout="row" fxLayoutAlign="center center">
                            <button class="mr-8" mat-raised-button matStepperPrevious type="button" color="accent">
                                Previous
                            </button>
                            <button mat-raised-button matStepperNext type="button" color="accent">
                                Next
                            </button>
                        </div>

                    </form>

                </mat-step>
                <mat-step [stepControl]="horizontalStepperStep6">

                    <form fxLayout="column" [formGroup]="horizontalStepperStep6">

                        <ng-template matStepLabel>Product Overview</ng-template>


                        <div class="mb-24 mt-24" fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="center center"
                            fxLayoutGap="10px">
                            <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">

                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label>Features</mat-label>
                                    <textarea matInput formControlName="features">
                                    </textarea>
                                    <mat-error>Features is required!</mat-error>
                                </mat-form-field>
                               
                            </div>

                        </div>

                        <div fxFlex="1 0 auto" fxLayout="row">
                            <mat-form-field appearance="outline" fxFlex="100" class="ml-4">
                                <mat-label>Minimum Quantity</mat-label>
                                <input type="number" matInput formControlName="miniqty" [(ngModel)]="qty" required>
                                <mat-error>Minimum Quantity is required!</mat-error>
                            </mat-form-field>

                        </div>
                        <div class="mb-24 mt-24" fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="center center"
                            fxLayoutGap="10px">
                            <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">

                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label>Product Information</mat-label>
                                    <textarea matInput formControlName="productinformation">
                                    </textarea>
                                    <mat-error>Product Information is required!</mat-error>
                                </mat-form-field>

                            </div>

                        </div>

                        <div fxLayout="row" fxLayoutAlign="center center">
                            <button class="mr-8" mat-raised-button matStepperPrevious type="button" color="accent">
                                Previous
                            </button>
                            <button mat-raised-button matStepperNext type="button" color="accent">
                                Next
                            </button>
                        </div>

                    </form>

                </mat-step>
                <!-- <mat-step [stepControl]="horizontalStepperStep7">

                    <form fxLayout="column" [formGroup]="horizontalStepperStep7">

                        <ng-template matStepLabel>Delivery Options</ng-template>

                        <div fxFlex="1 0 auto" fxLayout="row">
                            <mat-checkbox formControlName="freeDelivery ">Free Delivery</mat-checkbox>
                            
                        </div>
                        <div fxFlex="1 0 auto" fxLayout="row">
                            <mat-form-field appearance="fill">
                                <mat-label>Choose a date</mat-label>
                                <input matInput [matDatepicker]="picker">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                              </mat-form-field>
                             
                        </div>
                        <div fxFlex="1 0 auto" fxLayout="row">
                            <mat-checkbox formControlName="rushDelivery">Rush or Super Rush</mat-checkbox>
                        </div>
                        <div fxFlex="1 0 auto" fxLayout="row">
                            <mat-checkbox formControlName="multipleAddress ">SHIP TO MULTIPLE ADDRESSES</mat-checkbox>
                        </div>




                        <div fxLayout="row" fxLayoutAlign="center center">
                            <button class="mr-8" mat-raised-button matStepperPrevious type="button" color="accent">
                                Previous
                            </button>
                            <button mat-raised-button matStepperNext type="button" color="accent">
                                Next
                            </button>
                        </div>

                    </form>

                </mat-step> -->

                <mat-step [stepControl]="horizontalStepperStep7">

                    <form fxLayout="column" [formGroup]="horizontalStepperStep7">

                        <ng-template matStepLabel>Print Canvas</ng-template>

                        <div class="mb-24" fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="center center">

                            <div fxFlex="50%" *ngIf="showCanvasImage.front" class="text-center">
                                <h2>Front Image</h2>
                                <div class="main-product-image-wrapper">
                                    <img #image1 class="image" *ngIf="image1Path != null" [src]="image1Path">
                                </div>
                                <button mat-raised-button type="button" color="accent" class="button-wrapper"
                                    (click)="getFrontImageCord()">
                                    Set Print Canvas Area
                                </button>

                                <button mat-raised-button type="button" color="accent" class="button-wrapper"
                                    (click)="openFrontDialog()" [disabled]="disabledFront">
                                    Done
                                </button>
                            </div>

                            <div class="change-canvas-image">

                                <button matRipple type="button"
                                    (click)="showCanvasImage.front = true;showCanvasImage.back = false;">
                                    <img [src]="image1Path">
                                    <span>Front Image</span>
                                </button>

                                <button matRipple type="button"
                                    (click)="showCanvasImage.back = true;showCanvasImage.front = false;">
                                    <img [src]="image2Path">
                                    <span>Back Image</span>
                                </button>

                            </div>

                            <div fxFlex="50%" *ngIf="showCanvasImage.back" class="text-center">
                                <h2>Back Image</h2>
                                <div class="main-product-image-wrapper">
                                    <img #image2 class="image" *ngIf="image2Path != null" [src]="image2Path">
                                </div>
                                <button mat-raised-button type="button" color="accent" class="button-wrapper"
                                    (click)="getBackImageCord()">
                                    Set Print Canvas Area
                                </button>

                                <button mat-raised-button type="button" color="accent" class="button-wrapper"
                                    (click)="openBackDialog()" [disabled]="disabledBack">
                                    Done
                                </button>
                            </div>

                        </div>

                        <div fxLayout="row" fxLayoutAlign="center center">
                            <button class="mr-8" mat-raised-button matStepperPrevious type="button" color="accent">
                                Previous
                            </button>
                            <button mat-raised-button matStepperNext type="button" color="accent"
                                (click)="saveProduct()">
                                Save
                            </button>
                        </div>

                    </form>

                </mat-step>

            </mat-horizontal-stepper>
            <!-- / HORIZONTAL STEPPER EXAMPLE -->

        </div>

    </div>
    <!-- / CONTENT -->

</div>