import { Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { DesignToolService } from 'app/design-tool.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import Cropper from "cropperjs";
import { NgxSpinnerService } from "ngx-spinner";


@Component({
  selector: 'app-front-image',
  templateUrl: './front-image.component.html',
  styleUrls: ['./front-image.component.scss']
})
export class FrontImageComponent implements OnInit {

  // public buildings = [ ];
  // public showUploader = true;

  // public rect = { 
  //   w: 0,
  //   h: 0,
  //   x: 0,
  //   y: 0,
  //   startX: 0,
  //   startY: 0
  // };
  // public drag = false;


  public imageLeft: any;
  public imageTop: any;
  public imageWidth: any;
  public imageHeight: any;

  public disabled: boolean = true;

  @ViewChild("image", { static: false })
  public imageElement: ElementRef;
  private cropper: Cropper;

  constructor(
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private DesignToolService: DesignToolService,
    private router: Router
  ) { }

  ngOnInit(): void {
    //this.getImages();
  }

  getFrontImageCord() {
    console.log('here');

    this.cropper = new Cropper(this.imageElement.nativeElement, {
      zoomable: false,
      scalable: false,
      aspectRatio: 0,
      crop: () => {
        const canvas = this.cropper.getCropBoxData();
        this.imageLeft = (canvas.left / 350) * 100;
        this.imageTop = (canvas.top / 525) * 100;
        this.imageWidth = (canvas.width / 350) * 100;
        this.imageHeight = (canvas.height / 525) * 100;
      },
      cropend: () => {
        this.disabled = false;
      }
    });
  }



  // save() {
  //   let data = [];

  //   data = [{
  //     left: parseFloat(this.imageLeft).toFixed(2),
  //     top: parseFloat(this.imageTop).toFixed(2),
  //     width: parseFloat(this.imageWidth).toFixed(2),
  //     height: parseFloat(this.imageHeight).toFixed(2),
  //     isFrontImage: 1
  //   }];

  //   this.DesignToolService.saveDefaultImageCord(data[0]).subscribe((result: any) => {
  //     if (result.responseCode == 200 && result.status == true) {
  //       Swal.fire('Success', 'Saved Successfully', 'success').then(function () {
  //         window.location.reload();
  //       });
  //     } else if (result.responseCode == 400 && result.status == false) {
  //       Swal.fire('Error', result.message, 'error');
  //     } else {
  //       Swal.fire('Error', 'Something went wrong', 'error');
  //     }
  //   }, (error: HttpErrorResponse) => {
  //     if (error.status == 401) {
  //       Swal.fire('Error', error.error.message, 'error');
  //     } else {
  //       Swal.fire('Error', 'Something went wrong', 'error');
  //     }
  //   });

  openDialog() {
    const dialogRef = this.dialog.open(FrontPixelToInchDialog, {
      data: { left: this.imageLeft, top: this.imageTop, width: this.imageWidth, height: this.imageHeight }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }


  // public getImages() {
  //   //this.buildings = document.getElementById('imageImport')['files'];
  //   //const reader = new FileReader();

  //   //reader.onload = function (e) {
  //     const canvas: any = document.getElementById('imageCanvas');
  //     const ctx = canvas.getContext('2d');
  //     const img: any = new Image();
  //     img.onload = function () {
  //       canvas.width = img.width;
  //       canvas.height = img.height;
  //       ctx.drawImage(img, 0, 0);
  //     };
  //     img.src = 'https://mms-images-prod.imgix.net/mms/images/catalog/05866b675598f94469692fd2953397b4/colors/176000/views/alt/front_large_extended.png';
  //     //img.src = e.target['result'];
  //   //};
  //   //reader.readAsDataURL(this.buildings[0]);
  //   //this.showUploader = false;
  //   this.initDraw(document.getElementById('imageCanvas'),img);
  // }

  // public initDraw(canvas,img) {
  //   function setMousePosition(e) {
  //     const ev = e || window.event;
  //     if (ev.pageX) {
  //       mouse.x = ev.pageX + window.pageXOffset;
  //       mouse.y = ev.pageY + window.pageYOffset;
  //     } else if (ev.clientX) {
  //       mouse.x = ev.clientX + document.body.scrollLeft;
  //       mouse.y = ev.clientY + document.body.scrollTop;
  //     }
  //   }

  //   const mouse = {
  //     x: 0,
  //     y: 0,
  //     startX: 0,
  //     startY: 0
  //   };
  //   let element = null;

  //   canvas.onmousemove = function (e) {
  //     setMousePosition(e);
  //     if (element !== null) {
  //       element.style.width = Math.abs(mouse.x - mouse.startX) + 'px';
  //       element.style.height = Math.abs(mouse.y - mouse.startY) + 'px';
  //       element.style.left = (mouse.x - mouse.startX < 0) ? mouse.x + 'px' : mouse.startX + 'px';
  //       element.style.top = (mouse.y - mouse.startY < 0) ? mouse.y + 'px' : mouse.startY + 'px'; 

  //       let l = (mouse.x - mouse.startX < 0) ? mouse.x : mouse.startX;
  //       let t = (mouse.y - mouse.startY < 0) ? mouse.y : mouse.startY; 
  //       let w = (e.pageX - this.offsetLeft) - mouse.startX;//Math.abs(mouse.x - mouse.startX);
  //       let h = (e.pageY - this.offsetTop) - mouse.startY;//Math.abs(mouse.y - mouse.startY);

  //       const ctx = canvas.getContext('2d');
  //       //ctx.clearRect(0, 0, 500, 500);
  //       ctx.drawImage(img, 0, 0);
  //       ctx.strokeStyle = 'red';
  //       ctx.strokeRect(mouse.startX, mouse.startY, w, h);
  //     }
  //   };

  //   canvas.onclick = function (e) {
  //     if (element !== null) {
  //       element = null;
  //       canvas.style.cursor = 'default';
  //       console.log('finsihed.'); 
  //     } else {
  //       console.log('begun.');
  //       mouse.startX = mouse.x;
  //       mouse.startY = mouse.y;
  //       element = document.createElement('div');
  //       element.className = 'rectangle';
  //       element.style.left = mouse.x + 'px';
  //       element.style.top = mouse.y + 'px';
  //       canvas.appendChild(element);
  //       canvas.style.cursor = 'crosshair';
  //     }
  //   };
  // }
}

@Component({
  selector: 'front-pixel-to-inch-dialog',
  templateUrl: 'front-pixel-to-inch-dialog.html',
  styleUrls: ['./front-image.component.scss']
})
export class FrontPixelToInchDialog {

  public form;

  public imageLeft: any;
  public imageTop: any;
  public imageWidth: any;
  public imageHeight: any;

  @ViewChild("image", { static: false })
  public imageElement: ElementRef;

  constructor(
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private DesignToolService: DesignToolService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: FrontPixelToInchDialog
  ) { }

  ngOnInit(): void {
    this.imageLeft = this.data['left'];
    this.imageTop = this.data['top'];
    this.imageWidth = this.data['width'];
    this.imageHeight = this.data['height'];

    this.form = this._formBuilder.group({
      widthInch: new FormControl({ value: '1', disabled: true }),
      heightInch: new FormControl({ value: '1', disabled: true }),
      widthPxPerInch: ['', Validators.required],
      heightPxPerInch: ['', Validators.required],
    });
  }

  save() {
    if (this.form.invalid) {
      return false;
    } else {
      this.spinner.show();
      let data = [];

      data = [{
        left: parseFloat(this.imageLeft).toFixed(2),
        top: parseFloat(this.imageTop).toFixed(2),
        width: parseFloat(this.imageWidth).toFixed(2),
        height: parseFloat(this.imageHeight).toFixed(2),
        width_px_per_inch: this.form.value['widthPxPerInch'],
        height_px_per_inch: this.form.value['heightPxPerInch'],
        angle: 'front'
      }];

      this.DesignToolService.saveDefaultImageCord(data[0]).subscribe((result: any) => {
        this.spinner.hide();
        if (result.responseCode == 200 && result.status == true) {
          Swal.fire('Success', 'Saved Successfully', 'success').then(function () {
            window.location.reload();
          });
        } else if (result.responseCode == 400 && result.status == false) {
          Swal.fire('Error', result.message, 'error');
        } else {
          Swal.fire('Error', 'Something went wrong', 'error');
        }
      }, (error: HttpErrorResponse) => {
        this.spinner.hide();
        if (error.status == 401) {
          Swal.fire('Error', error.error.message, 'error');
        } else {
          Swal.fire('Error', 'Something went wrong', 'error');
        }
      });
    }
  }

}
