import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { DesignToolService } from 'app/design-tool.service';

@Injectable()
export class ColorListService implements Resolve<any> {

  
  colors: any[];
  onColorsChanged: BehaviorSubject<any>;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(
      private _httpClient: HttpClient,
      private DesignToolService: DesignToolService
  )
  {
      // Set the defaults
      this.onColorsChanged = new BehaviorSubject({});
  }
  baseURL = this.DesignToolService.baseURL;

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
  {
      return new Promise((resolve, reject) => {

          Promise.all([
              this.getProducts()
          ]).then(
              () => {
                  resolve();
              },
              reject
          );
      });
  }

  /**
   * Get products
   *
   * @returns {Promise<any>}
   */
  getProducts(): Promise<any>
  {
      return new Promise((resolve, reject) => {
        let auth_token = localStorage.getItem("token"); 
        let reqHeaders = new HttpHeaders({'Authorization': `Bearer ${auth_token}`,'accept': 'application/json' });
        this._httpClient.get(this.baseURL + 'admin/get-colors', { headers: reqHeaders })
            .subscribe((response: any) => {
                this.colors = response;
                this.onColorsChanged.next(this.colors);
                resolve(response);
            }, reject);
      });
  }
}
