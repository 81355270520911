import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { DesignToolService } from 'app/design-tool.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-add-fonts',
  templateUrl: './add-fonts.component.html',
  styleUrls: ['./add-fonts.component.scss']
})
export class AddFontsComponent implements OnInit 
{
    form: FormGroup;
    public formData = new FormData();
    public file: File = null;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _formBuilder: FormBuilder,
        private spinner: NgxSpinnerService,
        private DesignToolService: DesignToolService,
        private router: Router
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Reactive Form
        this.form = this._formBuilder.group({
            name : ['',Validators.required],
            file: ['',Validators.required]
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    uploadFile(event) {
      this.file = <File>event.target.files[0];
    }

    addFont(){
      if(this.form.invalid){
        return false;
      } else {
        this.spinner.show();
        let name = this.form.value['name'];
        
        this.formData.append('name', name);
        this.formData.append('file', this.file, this.file.name);

        this.DesignToolService.addFont(this.formData).subscribe((result: any) => {
          this.spinner.hide();
          if (result.responseCode == 200 && result.status == true) { 
            Swal.fire('Success','Font Added Successfully', 'success').then(function() {
              window.location.reload();
            });
          } else if(result.responseCode == 400 && result.status == false){
            this.formData = new FormData();
            Swal.fire('Error', result.message, 'error');
            } else {
              this.formData = new FormData();
              Swal.fire('Error','Something went wrong', 'error');
            }
        }, (error: HttpErrorResponse) => {
            this.spinner.hide();
            this.formData = new FormData();
            if(error.status == 401) {
                Swal.fire('Error',error.error.message, 'error');
            } else {
                Swal.fire('Error','Something went wrong', 'error');
            }
        });
      }
    }

}
