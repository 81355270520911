import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { DesignToolService } from 'app/design-tool.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-add-art-work-subcategory',
  templateUrl: './add-art-work-subcategory.component.html',
  styleUrls: ['./add-art-work-subcategory.component.scss']
})
export class AddArtWorkSubcategoryComponent implements OnInit, OnDestroy {
  form;
  public formData = new FormData();
  public uploadMaxSize = 1000000; // 1 mb
  public Image: File = null;
  public imagePath: any;
  public categories = [];
  public docAttachments = [];

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {FormBuilder} _formBuilder
   */
  constructor(
    private _formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private DesignToolService: DesignToolService,
    private router: Router
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // tslint:disable-next-line:typedef
  get a(): FormArray {
    return this.form.get('attachments_attributes') as FormArray;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.getCategories();
    // Reactive Form
    this.form = this._formBuilder.group({
      category: ['', Validators.required],
      name: ['', Validators.required],
      description: [''],
      attachments_attributes: this._formBuilder.array([
        this.initAttachment()
      ]),
    });

  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  getCategories() {
    try {
      this.DesignToolService.getArtWorkCategories().subscribe(
        (result: any) => {
          if (result.responseCode == 200 && result.status == true) {
            this.categories = result.data;
          } else {
            Swal.fire("", result.message, "error");
          }
        },
        (error: HttpErrorResponse) => {
          Swal.fire("", "Something went wrong", "error");
        }
      );
    } catch (error) {
      Swal.fire("", "Something went wrong", "error");
    }
  }

  // tslint:disable-next-line:typedef
  initAttachment() {
    return this._formBuilder.group({
      doc: ['']
    });
  }

  // tslint:disable-next-line:typedef
  addAttachment() {
    const control = this.form.controls.attachments_attributes as FormArray;
    control.push(this.initAttachment());
  }

  // tslint:disable-next-line:typedef
  removeAttachment(index) {
    const control = this.form.controls.attachments_attributes as FormArray;
    control.removeAt(index);
    this.docAttachments.splice(index, 1);
  }

  getImage(event, index) {
    if (event.target.files[0].size > this.uploadMaxSize) {
      event.target.value = null;
      Swal.fire('Warning', 'Max upload size should be less than equal to (250kb)', 'warning')
    } else {
      if (this.docAttachments.length <= index) { // if insert image at new index
        this.Image = <File>event.target.files[0];
        this.docAttachments.push({ 'item': this.Image, 'name': this.Image.name })
      }
      else { // if insert image at existing index 
        this.docAttachments.splice(index, 1);
        this.Image = <File>event.target.files[0];
        this.docAttachments.push({ 'item': this.Image, 'name': this.Image.name })
      }
    }
  }

  addArtWorkSubCategory() {
    if (this.form.invalid) {
      return false;
    } else {
      this.spinner.show();
      let name = this.form.value['name'];
      let head_id = this.form.value['category'];
      let description = this.form.value['description'];

      this.formData.append('name', name);
      this.formData.append('head_id', head_id);
      this.formData.append('description', description);
      this.formData.append('docLength', this.docAttachments.length.toString())
      this.docAttachments.forEach((e, i) => {
        this.formData.append('artWorkAttachments' + [i], e.item, e.name);
      });

      this.DesignToolService.addArtWorkCategory(this.formData).subscribe((result: any) => {
        this.spinner.hide();
        if (result.responseCode == 200 && result.status == true) {
          Swal.fire('Success', 'Category Created Successfully', 'success').then(function () {
            window.location.reload();
          });
        } else if (result.responseCode == 400 && result.status == false) {
          Swal.fire('Error', result.message, 'error');
        } else {
          Swal.fire('Error', 'Something went wrong', 'error');
        }
      }, (error: HttpErrorResponse) => {
        this.spinner.hide();
        if (error.status == 401) {
          Swal.fire('Error', error.error.message, 'error');
        } else {
          Swal.fire('Error', 'Something went wrong', 'error');
        }
      });
    }

  }

}
