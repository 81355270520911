<div id="products" class="page-layout carded fullwidth inner-scroll">

  <!-- TOP BACKGROUND -->
  <div class="top-bg accent"></div>
  <!-- / TOP BACKGROUND -->

  <!-- CENTER -->
  <div class="center">

    <!-- HEADER -->
    <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="space-between center">

      <!-- APP TITLE -->
      <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
          shopping_basket
        </mat-icon>
        <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
          Orders List
        </span>
      </div>
      <!-- / APP TITLE -->

    </div>
    <!-- / HEADER -->

    <!-- CONTENT CARD -->
    <div class="content-card">

      <mat-table class="products-table" #table [dataSource]="dataSource" matSort [@animateStagger]="{value:'50'}"
        fusePerfectScrollbar>

        <!-- ID Column -->
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef mat-sort-header>ID</mat-header-cell>
          <mat-cell *matCellDef="let product">
            <p class="text-truncate">{{product.id}}</p>
          </mat-cell>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Name</mat-header-cell>
          <mat-cell *matCellDef="let product" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{product.name}}</p>
          </mat-cell>
        </ng-container>

        <!-- Product Image Column -->
        <ng-container matColumnDef="productImage">
          <mat-header-cell *matHeaderCellDef>Image</mat-header-cell>
          <mat-cell *matCellDef="let product">
            <img class="product-image" *ngIf="product.product_images[0].image" [alt]="product.name"
              [src]="product.product_images[0].image" />
            <img *ngIf="product.product_images[0].image == null"
              [src]="'assets/images/ecommerce/product-image-placeholder.png'">
          </mat-cell>
        </ng-container>

        <!-- Quantity Column -->
        <ng-container matColumnDef="qty">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Quantity</mat-header-cell>
          <mat-cell *matCellDef="let product" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{product.total_quantity}}</p>
          </mat-cell>
        </ng-container>

        <!-- Price Column -->
        <ng-container matColumnDef="price">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Price</mat-header-cell>
          <mat-cell *matCellDef="let product" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{product.each_product_price}}</p>
          </mat-cell>
        </ng-container>

        <!-- Ship Charges Column -->
        <ng-container matColumnDef="shipCharges">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Ship Charges</mat-header-cell>
          <mat-cell *matCellDef="let product" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{product.shipping_charges}}</p>
          </mat-cell>
        </ng-container>

        <!-- Sale Price Column -->
        <ng-container matColumnDef="sale_price">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Sale Price</mat-header-cell>
          <mat-cell *matCellDef="let product" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{product.total_amount}}</p>
          </mat-cell>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
          <mat-cell *matCellDef="let product">
            <p class="text-truncate pointer"><a (click)="viewOrderDetail(product)">
                <mat-icon>remove_red_eye</mat-icon>
              </a></p>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

        <mat-row *matRowDef="let product; columns: displayedColumns;" class="product" matRipple>
        </mat-row>

      </mat-table>

      <mat-paginator #paginator [length]="dataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
        [pageSizeOptions]="[5, 10, 25, 100]">
      </mat-paginator>

    </div>
    <!-- / CONTENT CARD -->
  </div>
  <!-- / CENTER -->
</div>