<div id="forms" class="page-layout simple fullwidth" fxLayout="column">

    <!-- HEADER -->
    <div class="header accent p-24 h-160" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column" fxLayoutAlign="center start">
            <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="secondary-text s-18">home</mat-icon>
                <mat-icon class="secondary-text s-16">chevron_right</mat-icon>
                <span class="secondary-text">Design Tool Management</span>
            </div>
            <div class="h1 mt-16">Add Font</div>
        </div>
    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content p-24">
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-spin-fade" [fullScreen]="true">
        </ngx-spinner>        

        <div class="mb-24" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">

            <!-- REACTIVE FORM EXAMPLE -->

            <form class="mat-card mat-elevation-z4 p-24 mr-24" fxLayout="column" fxLayoutAlign="start"
                  fxFlex="1 0 auto" name="form" [formGroup]="form"  (ngSubmit)="addFont()">


                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

                    <mat-form-field appearance="outline" fxFlex="50" class="mr-4">
                        <mat-label>Name</mat-label>
                        <input class="font-input" matInput formControlName="name">
                    </mat-form-field>

                    <div class="image-input"
                        style="display: flex;flex-direction: column;box-sizing: border-box;flex: 1 0 auto;">
                        <input type="file" formControlName="file" (change)="uploadFile($event)" required>
                    </div>

                </div>

                <div fxLayout="row" fxLayoutAlign="right right">
                    <button class="mr-8" mat-raised-button color="accent">
                        Save
                    </button>
                </div>

            </form>

        </div>

    </div>
    <!-- / CONTENT -->

</div>




