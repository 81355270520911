<div id="forms" class="page-layout simple fullwidth" fxLayout="column">

    <!-- HEADER -->
    <div class="header accent p-24 h-160" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column" fxLayoutAlign="center start">
            <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="secondary-text s-18">home</mat-icon>
                <mat-icon class="secondary-text s-16">chevron_right</mat-icon>
                <span class="secondary-text">Art Work Management</span>
            </div>
            <div class="h1 mt-16">Add SubCategory</div>
        </div>
    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content p-24">
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-spin-fade" [fullScreen]="true">
        </ngx-spinner>

        <div class="mb-24" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">

            <!-- REACTIVE FORM EXAMPLE -->

            <form class="mat-card mat-elevation-z4 p-24 mr-24" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto"
                [formGroup]="form" (ngSubmit)="addArtWorkSubCategory()">

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

                    <mat-form-field appearance="outline" fxFlex="50" class="mr-4">
                        <mat-label>Category</mat-label>
                        <mat-select formControlName="category" required>
                            <mat-option *ngFor="let item of categories" [value]="item.id">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error>Category is required!</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="50" class="ml-4">
                        <mat-label>Name</mat-label>
                        <input matInput formControlName="name" required>
                        <mat-error>Name is required!</mat-error>
                    </mat-form-field>

                </div>

                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">

                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>Description</mat-label>
                        <textarea matInput formControlName="description">
                        </textarea>
                    </mat-form-field>

                </div>

                <div fxLayout="column" formArrayName="attachments_attributes">

                    <div class="image-input" fxFlex="1 0 auto" fxLayout="column"
                        style="display: flex;flex-direction: column;box-sizing: border-box;flex: 1 0 auto;"
                        *ngFor="let attachments_attribute of form.get('attachments_attributes')['controls'] let i=index"
                        [formGroupName]="i">
                        <input type="file" formControlName="doc" (change)="getImage($event,1)"
                            accept="image/png, image/jpeg, image/jpg" required>
                    </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="right right">
                    <button class="mr-8" type="button" mat-raised-button color="accent" (click)="addAttachment()">
                        Add Image
                    </button>
                    <button class="mr-8" mat-raised-button color="accent">
                        Save
                    </button>
                </div>

            </form>

        </div>

    </div>
    <!-- / CONTENT -->

</div>