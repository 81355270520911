import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Applications',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'dashboard',
                title: 'Dashboard',
                type: 'item',
                icon: 'dashboard',
                url: '/apps/dashboard'
            },
            {
                id: 'e-commerce',
                title: 'Order Management',
                translate: 'NAV.ECOMMERCE',
                type: 'collapsable',
                icon: 'shopping_basket',
                children: [
                    {
                        id: 'view-orders',
                        title: 'View Orders',
                        type: 'item',
                        url: 'view-orders',
                        exactMatch: true
                    },
                ]
            },
            {
                id: 'e-commerce',
                title: 'Product Management',
                translate: 'NAV.ECOMMERCE',
                type: 'collapsable',
                icon: 'shopping_basket',
                children: [
                    {
                        id: 'add-products',
                        title: 'Add Product',
                        type: 'item',
                        url: 'add-product',
                        exactMatch: true
                    },
                    {
                        id: 'view-product',
                        title: 'View Product',
                        type: 'item',
                        url: 'products',
                        exactMatch: true
                    },
                    {
                        id: 'add-size',
                        title: 'Add Size',
                        type: 'item',
                        url: 'add-size',
                        exactMatch: true
                    },
                    {
                        id: 'view-size',
                        title: 'View Size',
                        type: 'item',
                        url: 'sizes',
                        exactMatch: true
                    },
                    {
                        id: 'add-color',
                        title: 'Add Color',
                        type: 'item',
                        url: 'add-color',
                        exactMatch: true
                    },
                    {
                        id: 'view-color',
                        title: 'View Color',
                        type: 'item',
                        url: 'colors',
                        exactMatch: true
                    },
                    {
                        id: 'add-category',
                        title: 'Add Category',
                        type: 'item',
                        url: 'add-category',
                        exactMatch: true
                    },
                    {
                        id: 'view-category',
                        title: 'View Category',
                        type: 'item',
                        url: 'product-category',
                        exactMatch: true
                    },
                    {
                        id: 'add-subcategory',
                        title: 'Add SubCategory',
                        type: 'item',
                        url: 'add-subcategory',
                        exactMatch: true
                    },
                    {
                        id: 'view-subcategory',
                        title: 'View SubCategory',
                        type: 'item',
                        url: 'product-subcategory',
                        exactMatch: true
                    }
                ]
            },
            {
                id: 'e-commerce',
                title: 'Brand Management',
                translate: 'NAV.ECOMMERCE',
                type: 'collapsable',
                icon: 'branding_watermark',
                children: [
                    {
                        id: 'add-brand',
                        title: 'Add Brand',
                        type: 'item',
                        url: 'add-brand',
                        exactMatch: true
                    },
                    {
                        id: 'view-brand',
                        title: 'View Brand',
                        type: 'item',
                        url: 'brands',
                        exactMatch: true
                    }
                ]
            },
            {
                id: 'e-commerce',
                title: 'Fabric Management',
                translate: 'NAV.ECOMMERCE',
                type: 'collapsable',
                icon: 'waves',
                children: [
                    {
                        id: 'add-fabric',
                        title: 'Add Fabric',
                        type: 'item',
                        url: 'add-fabric',
                        exactMatch: true
                    },
                    {
                        id: 'view-fabric',
                        title: 'View Fabric',
                        type: 'item',
                        url: 'fabrics',
                        exactMatch: true
                    },
                    {
                        id: 'add-fabric-category',
                        title: 'Add Category',
                        type: 'item',
                        url: 'add-fabric-category',
                        exactMatch: true
                    },
                    {
                        id: 'view-fabric-category',
                        title: 'View Fabric Category',
                        type: 'item',
                        url: 'fabric-category',
                        exactMatch: true
                    }
                ]
            },
            {
                id: 'e-commerce',
                title: 'Design Tool Management',
                translate: 'NAV.ECOMMERCE',
                type: 'collapsable',
                icon: 'filter_b_and_w',
                children: [
                    {
                        id: 'frontImage',
                        title: 'Default Front Image',
                        type: 'item',
                        url: 'front-image',
                        exactMatch: true
                    },
                    {
                        id: 'backImage',
                        title: 'Default Back Image',
                        type: 'item',
                        url: 'back-image',
                        exactMatch: true
                    },
                    {
                        id: 'add-work-category',
                        title: 'Add Art Work Category',
                        type: 'item',
                        url: 'add-art-work-category',
                        exactMatch: true
                    },
                    {
                        id: 'add-work-category',
                        title: 'View Art Work Category',
                        type: 'item',
                        url: 'view-art-work-categories',
                        exactMatch: true
                    },
                    {
                        id: 'add-work-subcategory',
                        title: 'Add Art Work SubCategory',
                        type: 'item',
                        url: 'add-art-work-subcategory',
                        exactMatch: true
                    },
                    {
                        id: 'add-work-subcategory',
                        title: 'View Art Work SubCategory',
                        type: 'item',
                        url: 'view-art-work-subcategories',
                        exactMatch: true
                    },
                    {
                        id: 'add-fonts',
                        title: 'Add Fonts',
                        type: 'item',
                        url: 'add-fonts',
                        exactMatch: true
                    }
                ]
            }
        ]
    },
];
