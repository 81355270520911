import { NgModule, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AgmCoreModule } from '@agm/core';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FormsModule } from "@angular/forms";
import { AngularFileUploaderModule } from 'angular-file-uploader';
import { NgxSpinnerModule } from 'ngx-spinner';

import { AddArtWorkCategoryComponent } from './add-art-work-category/add-art-work-category.component';
import { AddArtWorkSubcategoryComponent } from './add-art-work-subcategory/add-art-work-subcategory.component';
import { ViewArtWorkCategoryComponent } from './view-art-work-category/view-art-work-category.component';
import { ViewArtWorkCategoryService } from './view-art-work-category/view-art-work-category.service';
import { ViewArtWorkSubcategoryComponent } from './view-art-work-subcategory/view-art-work-subcategory.component';
import { ViewArtWorkSubcategoryService } from './view-art-work-subcategory/view-art-work-subcategory.service';


const routes: Routes = [

  {
    path: 'add-art-work-category',
    component: AddArtWorkCategoryComponent
  },
  {
    path     : 'view-art-work-categories',
    component: ViewArtWorkCategoryComponent,
    resolve  : {
        data: ViewArtWorkCategoryService
    }
  },
  {
    path: 'add-art-work-subcategory',
    component: AddArtWorkSubcategoryComponent
  },
  {
    path     : 'view-art-work-subcategories',
    component: ViewArtWorkSubcategoryComponent,
    resolve  : {
        data: ViewArtWorkSubcategoryService
    }
  }

];

@NgModule({
  declarations: [AddArtWorkCategoryComponent, AddArtWorkSubcategoryComponent, ViewArtWorkCategoryComponent, ViewArtWorkSubcategoryComponent],
  imports: [
    FormsModule,
    CommonModule,
    RouterModule.forChild(routes),
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatStepperModule,
    MatPaginatorModule,
    MatTableModule,
    MatChipsModule,
    MatRippleModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatSortModule,
    MatTabsModule,
    MatCheckboxModule,
    NgxChartsModule,
    AgmCoreModule,
    FuseWidgetModule,

    //npm modules
    NgxSpinnerModule,

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
    AngularFileUploaderModule
  ],
  providers: [
    ViewArtWorkCategoryService,
    ViewArtWorkSubcategoryService
  ]
})
export class ArtWorkManagementModule { }
