<div id="forms" class="page-layout simple fullwidth" fxLayout="column">

    <!-- CONTENT -->
    <div class="content p-24">
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-spin-fade" [fullScreen]="true">
        </ngx-spinner>


        <div class="mb-24" fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">

            <div fxFlex="62.5%" class="text-center">
                <h2>Back Image</h2>
                <div class="main-product-image-wrapper">
                    <img #image class="image" src="./../../../../../assets/images/back.png">
                    <!-- https://api.labvilla.com/storage/default-image/back.png -->
                </div>

                <button mat-raised-button type="button" color="accent" class="button-wrapper"
                    (click)="getBackImageCord()">
                    Set Print Canvas Area
                </button>

                <button mat-raised-button type="button" color="accent" class="button-wrapper" (click)="openDialog()"
                    [disabled]="disabled">
                    Done
                </button>

            </div>

        </div>

    </div>

</div>