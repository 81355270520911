<div id="forms" class="page-layout simple fullwidth" fxLayout="column">

  <!-- Breadcrumb -->
  <div class="px-16 py-8 h-auto" fxLayout="row" fxLayoutAlign="start center">
    <!-- <div fxLayout="column" fxLayoutAlign="center start">
      <div fxLayout="row" fxLayoutAlign="start center"> -->
    <span>Order Status</span>
    <mat-icon class="s-16">chevron_right</mat-icon>
    <span>Order # TT-100{{orderId}}</span>
    <!-- </div>
    </div> -->
  </div>
  <!-- / Breadcrumb -->

  <!-- CONTENT -->
  <div class="content px-16 py-12">

    <div class="mb-16" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="10px">
      <div fxFlex="100%">
        <h1 class="order-number">Order # TT-100{{orderId}}</h1>
        <!-- <p [ngClass]="'tag-orange'" class="mx-8 my-0">Success</p>
        <p [ngClass]="'tag-blue'" class="mx-8 my-0">filled</p> -->
      </div>
      <div fxFlex="100%">
        <h3 class="m-0">Order Date {{orderDate}}</h3>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">
      <div fxFlex="65%" fxLayout="column" fxLayoutGap="15px">
        <div class="card" fxFlex="100%">
          <div class="heading ">
            <h2>Order Summary</h2>
          </div>
          <!-- <div class="ship" fxLayout="row" fxLayoutAlign="space-between center">
            <h4>Product to Ship</h4>
            <button fxFlexAlign="end">Add Tracking No</button>
          </div> -->
          <div class="px-12 py-12" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px">
            <!-- <div class="text-center" fxFlex="15%">
              <img src="../../../../../assets/images/avatars/Abbott.jpg">
            </div> -->
            <div fxFlex>
              <div fxLayout="row" fxLayoutAlign="space-between start">
                <!-- <ng-container *ngFor="let menu of menus;let i = index;">
                  <h5>{{menu.menu_name}}: {{menu.from}} - {{menu.to}} Plan</h5>
                </ng-container> -->
                <!-- <h5>AU$123</h5> -->
              </div>
              <!-- <p>Item Variant: No Change</p> -->

              <mat-divider></mat-divider>

              <!-- <div class="mt-12" fxLayout="row" fxLayoutAlign="space-between start">
                <p>Subcription: Chanllenge Plan</p>
                <p>Order 1</p>
              </div>
              <a href="javascript:void(0)">View Subcription</a> -->
            </div>
          </div>
        </div>
        <div class="card" fxFlex="100%">
          <div class="heading" fxLayout="row" fxLayoutAlign="space-between center">
            <div>
              <h2>Payment Info</h2>
              <!-- <h4 class="m-0">Paid with Cash</h4> -->
            </div>
          </div>
          <div class="p-12" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="5px">
            <div fxFlex="100%">
              <div fxLayout="row" fxLayoutAlign="space-between start">
                <p>Each Product</p>
                <p fxFlexAlign="end">${{productPrice}}</p>
              </div>
              <div fxLayout="row" fxLayoutAlign="space-between start">
                <p>Quantity</p>
                <p fxFlexAlign="end">{{quantity}}</p>
              </div>
              <div fxLayout="row" fxLayoutAlign="space-between start">
                <p>Shipping</p>
                <p fxFlexAlign="end">${{shippingCharges}}</p>
              </div>
              <!-- <div fxLayout="row" fxLayoutAlign="space-between start">
                <p>Tax</p>
                <p fxFlexAlign="end">$80</p>
              </div> -->
              <mat-divider></mat-divider>
              <div class="mt-12" fxLayout="row" fxLayoutAlign="space-between start">
                <p><b>Total</b></p>
                <p fxFlexAlign="end"><b>${{total}}</b></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card" fxFlex="34%">
        <div class="heading">
          <h2>Order Info</h2>
        </div>
        <div class="p-12">
          <div class="my-8" fxLayout="row" fxLayoutAlign="start start">
            <img class="mx-4 user-icon" src="../../../../../assets/images/avatars/customer.png">
            <h3 class="m-0" href="javascript:void(0)" fxFlexAlign="center">{{customerName}}</h3>
          </div>
          <p>{{customerEmail}}</p>

          <mat-divider></mat-divider>

          <h5>Shipping Address</h5>
          <!-- <p>{{customerName}}</p> -->
          <p>{{shippingAddress}}</p>
          <!-- <p>city, state, postal code</p> -->
          <!-- <p>03308530226 </p> -->

          <mat-divider></mat-divider>

          <h5>Billing Address</h5>
          <!-- <p>{{customerName}}</p> -->
          <p>{{billingAddress}}</p>
          <!-- <p>city, state, postal code</p>
          <p>03308530226 </p> -->

          <mat-divider></mat-divider>

          <h5>Delivery Method</h5>
          <p>Standard Delivery</p>
        </div>
      </div>
    </div>

  </div>
  <!-- / CONTENT -->

</div>