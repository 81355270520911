<div id="forms" class="page-layout simple fullwidth" fxLayout="column">

    <!-- HEADER -->
    <div class="header accent p-24 h-160" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column" fxLayoutAlign="center start">
            <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="secondary-text s-18">home</mat-icon>
                <mat-icon class="secondary-text s-16">chevron_right</mat-icon>
                <span class="secondary-text">Product Management</span>
            </div>
            <div class="h1 mt-16">Add Color</div>
        </div>
    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content p-24">
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-spin-fade" [fullScreen]="true">
        </ngx-spinner>        

        <div class="mb-24" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">

            <!-- REACTIVE FORM EXAMPLE -->

            <form class="mat-card mat-elevation-z4 p-24 mr-24" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto"
                name="form" [formGroup]="form" (ngSubmit)="addColor()">

                <div fxLayout="row" fxLayoutAlign="start center">

                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                        <mat-label>Name</mat-label>
                        <input matInput formControlName="name" required>
                        <mat-error>Name is required!</mat-error>
                    </mat-form-field>

                    <div class="image-input">
                        <!-- <input type="file"  formControlName="image" (change)="getImage($event,1)"
                                    accept="image/png, image/jpeg, image/jpg" required> -->
                        <label>Select Color</label>
                        <input [style.background]="color" [(colorPicker)]="color"
                            (colorPickerClose)="getColorCode($event)" required />

                    </div>

                </div>

                <div fxLayout="row" fxLayoutAlign="right right">
                    <button class="mr-8" mat-raised-button color="accent">
                        Save
                    </button>
                </div>

            </form>

        </div>

    </div>
    <!-- / CONTENT -->

</div>