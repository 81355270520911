import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { DesignToolService } from 'app/design-tool.service';

@Injectable()
export class ViewArtWorkCategoryService implements Resolve<any> {

  
  brands: any[];
  onBrandsChanged: BehaviorSubject<any>;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(
      private _httpClient: HttpClient,
      private DesignToolService: DesignToolService
  )
  {
      // Set the defaults
      this.onBrandsChanged = new BehaviorSubject({});
  }

  baseURL = this.DesignToolService.baseURL;
  imgUrl = this.DesignToolService.imgURL;


  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
  {
      return new Promise((resolve, reject) => {

          Promise.all([
              this.getProducts()
          ]).then(
              () => {
                  resolve();
              },
              reject
          );
      });
  }

  /**
   * Get products
   *
   * @returns {Promise<any>}
   */
  getProducts(): Promise<any>
  {
      return new Promise((resolve, reject) => {
        let auth_token = localStorage.getItem("token"); 
        let reqHeaders = new HttpHeaders({'Authorization': `Bearer ${auth_token}`,'accept': 'application/json' });
          this._httpClient.get(this.baseURL + 'admin/get-artwork-category', { headers: reqHeaders })
              .subscribe((response: any) => {
                  this.brands = response;
                  this.onBrandsChanged.next(this.brands);
                  resolve(response);
              }, reject);
      });
  }
}
