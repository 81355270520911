<!-- DIALOG -->
<div class="dialog">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-spin-fade" [fullScreen]="true">
  </ngx-spinner>   

  <h2>Pixel To Inch Ratio</h2>

  <form fxLayout="column" [formGroup]="form">
    <!-- Width -->
    <div class="pt-20">
      <span class="title">Width</span>

      <mat-form-field appearance="outline">
        <mat-label>inch</mat-label>
        <input type="number" matInput formControlName="widthInch">
        <mat-error>inch is required!</mat-error>
      </mat-form-field>

      <span class="title">=</span>

      <mat-form-field appearance="outline">
        <mat-label>pixel</mat-label>
        <input type="number" matInput formControlName="widthPxPerInch">
        <mat-error>pixel is required!</mat-error>
      </mat-form-field>

    </div>
    <!-- End Width -->

    <!-- Height -->
    <div class="pt-20">
      <span class="title">Height</span>

      <mat-form-field appearance="outline">
        <mat-label>inch</mat-label>
        <input type="number" matInput formControlName="heightInch">
        <mat-error>inch is required!</mat-error>
      </mat-form-field>

      <span class="title">=</span>

      <mat-form-field appearance="outline">
        <mat-label>pixel</mat-label>
        <input type="number" matInput formControlName="heightPxPerInch">
        <mat-error>pixel is required!</mat-error>
      </mat-form-field>

    </div>
    <!-- End Height -->

    <!-- Button -->
    <div class="pt-20 text-right">

      <button mat-raised-button type="button" class="ml-4" color="accent" (click)="save()">
        Save
      </button>

    </div>
    <!-- End Button -->
  </form>
</div>
<!-- / DIALOG -->