import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit {

  public orderId: any;
  public orderDate: any;
  public quantity: any;
  public productPrice: any;
  public shippingCharges: any;
  public total: any;
  public customerName: any;
  public customerEmail: any;
  public shippingAddress: any;
  public billingAddress: any;

  constructor() { }

  ngOnInit(): void {
    this.getOrderDetail()
  }

  getOrderDetail() {
    const data: any = JSON.parse(localStorage.getItem('orderDetail'));
    console.log('file: order-detail.component.ts => line 29 => OrderDetailComponent => getOrderDetail => data', data)
    this.orderId = data.id;
    this.orderDate = data.order_date;
    this.quantity = data.total_quantity;
    this.productPrice = data.each_product_price;
    this.shippingCharges = data.shipping_charges;
    this.total = data.total_amount;
    this.customerName = data.name;
    this.customerEmail = data.email;
    this.shippingAddress = data.shipping_address;
    this.billingAddress = data.billing_address;
  }

}
