import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { DesignToolService } from 'app/design-tool.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
    selector: 'app-add-category',
    templateUrl: './add-category.component.html',
    styleUrls: ['./add-category.component.scss']
})
export class AddCategoryComponent implements OnInit, OnDestroy {
    form: FormGroup;
    public formData = new FormData();
    public uploadMaxSize = 1000000; // 1 mb
    public Image: File = null;
    public imagePath: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _formBuilder: FormBuilder,
        private spinner: NgxSpinnerService,
        private DesignToolService: DesignToolService,
        private router: Router
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Reactive Form
        this.form = this._formBuilder.group({
            name : ['', Validators.required],
            qty : ['', Validators.required],
            description   : [''],
            image   : ['', Validators.required],
        });

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    getImage(event, imagePos) {
        if (event.target.files[0].size > this.uploadMaxSize) {
            event.target.value = null;
            Swal.fire('Warning', 'Max upload size should be less than equal to (250kb)', 'warning')
        } else {
            this.Image = <File>event.target.files[0];

            let reader = new FileReader();
            reader.readAsDataURL(this.Image);
            reader.onload = (_event) => {
                this.imagePath = reader.result;
            }
        }
    }

    addCategory() {
        if (this.form.invalid) {
            return;
        } else {
            this.spinner.show();
            let name = this.form.value['name'];
            let qty = this.form.value['qty'];
            let description = this.form.value['description'];

            this.formData.append('name',name);
            this.formData.append('head_id','');
            this.formData.append('qty',qty);
            this.formData.append('description',description);
            this.formData.append('image',this.Image,this.Image.name);

            this.DesignToolService.addCategory(this.formData).subscribe((result: any) => {
                this.spinner.hide();
                if (result.responseCode == 200 && result.status == true) {
                    Swal.fire('Success', 'Category Created Successfully', 'success').then(function () {
                        window.location.reload();
                    });
                } else if (result.responseCode == 400 && result.status == false) {
                    Swal.fire('Error', result.message, 'error');
                } else {
                    Swal.fire('Error', 'Something went wrong', 'error');
                }
            }, (error: HttpErrorResponse) => {
                this.spinner.hide();
                if (error.status == 401) {
                    Swal.fire('Error', error.error.message, 'error');
                } else {
                    Swal.fire('Error', 'Something went wrong', 'error');
                }
            });
        }
    }

}
