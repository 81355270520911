import { Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { DesignToolService } from 'app/design-tool.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import Cropper from "cropperjs";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-back-image',
  templateUrl: './back-image.component.html',
  styleUrls: ['./back-image.component.scss']
})
export class BackImageComponent implements OnInit {

  public imageLeft: any;
  public imageTop: any;
  public imageWidth: any;
  public imageHeight: any;

  public disabled: boolean = true;

  @ViewChild("image", { static: false })
  public imageElement: ElementRef;
  private cropper: Cropper;

  constructor(
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private DesignToolService: DesignToolService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  getBackImageCord() {
    this.cropper = new Cropper(this.imageElement.nativeElement, {
      zoomable: false,
      scalable: true,
      aspectRatio: 0,
      crop: () => {
        const canvas = this.cropper.getCropBoxData();
        this.imageLeft = (canvas.left / 350) * 100;
        this.imageTop = (canvas.top / 525) * 100;
        this.imageWidth = (canvas.width / 350) * 100;
        this.imageHeight = (canvas.height / 525) * 100;
      },
      cropend: () => {
        this.disabled = false;
      }
    });
  }

  // save(){
  //   let data = [];

  //   data = [{
  //     left: parseFloat(this.imageLeft).toFixed(2),
  //     top: parseFloat(this.imageTop).toFixed(2),
  //     width: parseFloat(this.imageWidth).toFixed(2),
  //     height: parseFloat(this.imageHeight).toFixed(2),
  //     isFrontImage: 0
  //   }];

  //   this.DesignToolService.saveDefaultImageCord(data[0]).subscribe((result: any) => {
  //     if (result.responseCode == 200 && result.status == true) { 
  //       Swal.fire('Success','Saved Successfully', 'success').then(function() {
  //         window.location.reload();
  //       });
  //     } else if(result.responseCode == 400 && result.status == false){
  //       Swal.fire('Error', result.message, 'error');
  //       } else {
  //           Swal.fire('Error','Something went wrong', 'error');
  //       }
  //   }, (error: HttpErrorResponse) => {
  //       if(error.status == 401) {
  //           Swal.fire('Error',error.error.message, 'error');
  //       } else {
  //           Swal.fire('Error','Something went wrong', 'error');
  //       }
  //   });

  // }

  openDialog() {
    const dialogRef = this.dialog.open(BackPixelToInchDialog,{
      data : {left:this.imageLeft, top:this.imageTop, width: this.imageWidth, height:this.imageHeight}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}

@Component({
  selector: 'back-pixel-to-inch-dialog',
  templateUrl: 'back-pixel-to-inch-dialog.html',
  styleUrls: ['./back-image.component.scss']
})
export class BackPixelToInchDialog {

  public form;

  public imageLeft: any;
  public imageTop: any;
  public imageWidth: any;
  public imageHeight: any;

  @ViewChild("image", { static: false })
  public imageElement: ElementRef;

  constructor(
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private DesignToolService: DesignToolService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: BackPixelToInchDialog
  ) { }

  ngOnInit(): void {
    this.imageLeft = this.data['left'];
    this.imageTop = this.data['top'];
    this.imageWidth = this.data['width'];
    this.imageHeight = this.data['height'];

    this.form = this._formBuilder.group({
      widthInch: new FormControl({value: '1', disabled: true}),
      heightInch: new FormControl({value: '1', disabled: true}),
      widthPxPerInch: ['',Validators.required],
      heightPxPerInch: ['',Validators.required],
    });
  }

  save() {
    if(this.form.invalid){
      return false;
    } else{
      this.spinner.show();
      let data = [];

      data = [{
        left: parseFloat(this.imageLeft).toFixed(2),
        top: parseFloat(this.imageTop).toFixed(2),
        width: parseFloat(this.imageWidth).toFixed(2),
        height: parseFloat(this.imageHeight).toFixed(2),
        width_px_per_inch: this.form.value['widthPxPerInch'],
        height_px_per_inch: this.form.value['heightPxPerInch'],
        angle: 'back'
      }];
  
      this.DesignToolService.saveDefaultImageCord(data[0]).subscribe((result: any) => {
        this.spinner.hide();
        if (result.responseCode == 200 && result.status == true) {
          Swal.fire('Success', 'Saved Successfully', 'success').then(function () {
            window.location.reload();
          });
        } else if (result.responseCode == 400 && result.status == false) {
          Swal.fire('Error', result.message, 'error');
        } else {
          Swal.fire('Error', 'Something went wrong', 'error');
        }
      }, (error: HttpErrorResponse) => {
        this.spinner.hide();
        if (error.status == 401) {
          Swal.fire('Error', error.error.message, 'error');
        } else {
          Swal.fire('Error', 'Something went wrong', 'error');
        }
      });
    }
  }

}
